import request from '@/utils/request'

// 登录
export function CheckUser (data) {
  return request({
    url: '/Plat/CheckUser',
    method: 'post',
    data
  })
}

// 获取登记人员信息
export function GetOldManPage (query) {
  return request({
    url: '/OldMan/GetOldManPage',
    method: 'get',
    params: query
  })
}
// 获取我采集的老人信息列表
export function GetMyOldManPage (query) {
  return request({
    url: '/OldMan/GetMyOldManPage',
    method: 'get',
    params: query
  })
}
// 删除老人信息
export function DeleteOldMan (data) {
  return request({
    url: '/OldMan/DeleteOldMan',
    method: 'post',
    data
  })
}
// 添加修改老人信息
export function SaveOldMan (data) {
  return request({
    url: '/OldMan/SaveOldMan',
    method: 'post',
    data
  })
}
// 添获取组织机构树[小程序]
export function WeGetOrganTree (data) {
  return request({
    url: '/Organ/WeGetOrganTree',
    method: 'post',
    data
  })
}
// 获取指定组织机构的下级组织机构列表
export function GetChildOrgansNullToken (query) {
  return request({
    url: '/Organ/GetChildOrgansNullToken',
    method: 'get',
    params: query
  })
}
// 添加修改老人信息(自行添加,需审核)
export function WeSaveOldMan (data) {
  return request({
    url: '/OldMan/WeSaveOldMan',
    method: 'post',
    data
  })
}
// 获取字典别表
export function GetCodeEntrysList (params) {
  return request({
    url: 'Code/GetCodeEntrysList',
    method: 'get',
    params
  })
}
// 获取老人信息列表
export function WeGetOldManPage (params) {
  return request({
    url: 'OldMan/WeGetOldManPage',
    method: 'get',
    params
  })
}